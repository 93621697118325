<template>
	<div class="div1">

		<div class="div2">
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :xl="6" :lg="7">
					<h2>欢迎来到 <el-image :src="require('@/assets/log2l.svg')" style=" height: 20px; width: 20px;">
						</el-image><span style="color:#1F26EF;"> SandCastle </span>系统</h2>
					<el-image :src="require('@/assets/DataMg.jpg')" style="height: 180px; width: 180px;"></el-image>
					<!-- <p>公众号 MarkerHub</p>
			<p>扫码二维码，回复【 VueAdmin 】获取登录密码</p> -->
				</el-col>

				<el-col :span="1">
					<el-divider direction="vertical"></el-divider>
				</el-col>

				<el-col :xl="6" :lg="7">
					<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="80px">
						<el-form-item label="用户名" prop="username" style="width: 380px;">
							<el-input v-model="loginForm.username"></el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password" style="width: 380px;">
							<el-input v-model="loginForm.password" show-password></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="code" style="width: 380px;">
							<el-input v-model="loginForm.code" @keyup.enter.native="submitForm('loginForm')" style="width: 172px; float: left" maxlength="5">
							</el-input>
							<el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>
						</el-form-item>

						<el-form-item>
							<el-button type="warning" @click="dialogVisible = true">新用户注册</el-button>
							<el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
							<el-button @click="resetForm('loginForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>

			<!--新增对话框-->
			<el-dialog title="新用户注册" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">

				<el-form :model="newUserForm" :rules="newUserRules" ref="newUserForm">
					<!-- <el-form-item label="用户名" prop="newuser" label-width="100px">
						<el-input v-model="newUserForm.newuser" autocomplete="off"></el-input>
					</el-form-item> -->
					<el-form-item label="手机号" prop="phone" label-width="100px">
						<el-input v-model="newUserForm.phone" placeholder="手机号将作为用户名登陆！" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="pwd" label-width="100px">
						<el-input v-model="newUserForm.pwd" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmpwd" label-width="100px">
						<el-input v-model="newUserForm.confirmpwd" autocomplete="off"></el-input>
					</el-form-item>

					<!-- <el-form-item label="邮箱" prop="email" label-width="100px">
						<el-input v-model="newUserForm.email" autocomplete="off"></el-input>
					</el-form-item> -->

				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="resetNewUserForm('newUserForm')">取 消</el-button>
					<el-button type="primary" @click="submitNewUserForm('newUserForm')">确 定</el-button>
				</div>
			</el-dialog>
		</div>

		<div class="div3">
			<span style="fontSize:12px"><a href="https://beian.miit.gov.cn/" target="_blank">备案号:鄂ICP备2021021969号-1</a></span>
		</div>
	</div>



</template>

<script>
import qs from 'qs'

export default {
	name: "Login",
	data() {
		//这里是自定义的规则
		var validateConfirmPwd = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入密码'));
			} else if (value !== this.newUserForm.pwd) {
				return callback(new Error('两次输入密码不一致!'));
			} else {
				callback()
			}
		};

		var validatePhone = (rule, value, callback) => {
			const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
			let res = reg.test(value);
			//alert(res);
			if (!value) {
				return callback(new Error('请输入电话号码'));
			} else if (!res) {
				return callback(new Error('电话号码格式不对'));
			} else {
				callback()
			}
		};

		return {
			loginForm: {
				username: '',
				password: '',
				code: '',
				token: ''
			},
			rules: {
				username: [
					{ required: true, message: '请输入用户名', trigger: 'blur' }
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' }
				],
				code: [
					{ required: true, message: '请输入验证码', trigger: 'blur' },
					{ min: 5, max: 5, message: '长度为 5 个字符', trigger: 'blur' }
				],
			},
			newUserRules: {
				// newuser: [
				// 	{ required: true, message: '请输入用户名', trigger: 'blur' }
				// ],
				pwd: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
					{ min: 3, message: '长度至少为 3 个字符', trigger: 'blur' }
				],
				confirmpwd: [
					// { required: true, message: '请输入确认密码', trigger: 'blur' },
					{ required: true, message: '密码和确认密码不一致', validator: validateConfirmPwd, trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '电话号码格式不对', validator: validatePhone, trigger: 'blur' }
				]
			},
			dialogVisible: false,
			newUserForm: {

			},
			captchaImg: undefined
		};  
	},
	methods: {
		submitForm(formName) {
			// console.log("============aaa=====================")
			// console.log(qs.stringify(this.loginForm))
			// console.log("============bbb=====================")
			this.$refs[formName].validate((valid) => {
				let query =  qs.stringify(this.loginForm);
				if (valid) {
					this.$axios.post('/login?' + query).then(res => {
						console.log(res)
						const jwt = res.headers['authorization']
						this.$store.commit('SET_TOKEN', jwt)
						this.$router.push("/index")
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		getCaptcha() {
			let query2 = "/captcha?virtueparam="+(Math.random()*10000000000+1).toString().substring(1,8)
			// let query2 = "/captcha"
			//console.log("query2: ", query2);
			// this.$axios.get('/captcha').then(res => {
			this.$axios.get(query2).then(res => {
				console.log("query2 in get captcha: ",query2)
				console.log(res)
				if(res.status ==200){
					this.loginForm.token = res.data.data.token
					this.captchaImg = res.data.data.captchaImg
				}

				// this.loginForm.code = ''
			})
		},

		resetNewUserForm(newUserForm) {
			this.$refs[newUserForm].resetFields();
			this.dialogVisible = false
			this.newUserForm = {}
		},
		handleClose() {
			this.resetNewUserForm('newUserForm')
		},
		//新用户注册	
		submitNewUserForm(newUserForm) {
			this.$refs[newUserForm].validate((valid) => {
				if (valid) {
					this.$axios.post('/sys/user/register', {
						username: this.newUserForm.phone,
						password: this.newUserForm.pwd,
						phone: this.newUserForm.phone
					})
						.then(res => {
							this.$message({
								showClose: true,
								message: '恭喜你，操作成功',
								type: 'success',
								onClose: () => {
									this.handleClose()
								}
							});
							this.dialogVisible = false
						})
						.catch((error) => {
							alert(error);
						})
				} else {
					console.log('error submit!!');
					return false;
				}
			});

		}
	},
	created() {
		this.getCaptcha()
	}
}
</script>

<style scoped>
.div1 {
	margin-top: 0;
	height: 100%;
	overflow: hidden;
	/* background-color: #fafafa; */
}
.div2 {
	margin-top: 3%;
	height: 90%;
	overflow: hidden;
	/* background-color: #fafafa; */
}
.div3 {
	height: 32px;
	line-height: 20px;
	text-align: center;
	/* height: 5%; */
	/* overflow: hidden; */
	/* background-color: #fafafa; */
}

.el-row {
	/* background-color: #fafafa; */
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}

.el-divider {
	height: 200px;
}

.captchaImg {
	float: left;
	margin-left: 8px;
	border-radius: 4px;
}
</style>